import React from "react";
import styled from "styled-components";

import logo from "../assets/images/instagram.svg";

const imageDimensions = "25px";

const Link = styled.a`
  display: inline-block;
  padding-top: ${props => props.theme.spacingTiny};
  padding-left: ${props => props.theme.spacingTiny};
`;

const Image = styled.img`
  height: ${imageDimensions};
  width: ${imageDimensions};
`;

export default ({ className, tag }) => (
  <Link
    href={`https://www.instagram.com/explore/tags/${tag}`}
    target="_blank"
    rel="noopener"
    className={className}
  >
    <Image src={logo} alt="Example pictures of flavour" />
  </Link>
);
