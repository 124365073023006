import React from "react"

import { InlineLink, BodyText, Section, PageContainer } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"
import FlavourLink from "../components/FlavourLink"

import flavours from "../flavours.json";

const Page = () => (
  <Layout>
    <SEO title="Flavours" />
    <PageContainer>
    <PageContainer.Title text="flavours.title" />
    <BodyText>
      My poor family have been eating the “uggos” for years and with that my flavours have developed names to whom they are betrothed. 
      If you ask for a cake flavour and eat it as much as one of my village, you may just be lucky enough to join the club.
    </BodyText>
    {flavours.map(f => (
      <Section key={f.name}>
        <Section.Header>"{f.name}" <FlavourLink tag={f.tag} /></Section.Header>
        <Section.Body>{f.description}</Section.Body>
      </Section>
    ))}
    <BodyText>
      There are many more unclaimed, <InlineLink to="/orphans">you could choose to adopt any of these</InlineLink>. If you have a flavour combination or favourite sweet you want me
      to try and create in cake form, let's try!
    </BodyText>
    <ContactLink>Let's do this!</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
